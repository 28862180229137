import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from "./router";
import Server from "./utils/server";
import Common from "./utils/common";
import Ajax from "./utils/ajax";
import LocalStorage from "./utils/localstorage";
import moment from 'moment'

// 按需引入 Bootstrap Icon
import {
    BootstrapVue,
    BIcon,
    BIconDownload,
    BIconExclamationCircleFill,
    BIconThreeDots,
    BIconArrowClockwise,
    BForm,
    BFormInput,
    BButton,
    BInputGroup,
    RouterLink,
} from 'bootstrap-vue'

Vue.use(BootstrapVue);
Vue.component('BForm', BForm);
Vue.component('BFormInput', BFormInput);
Vue.component('BButton', BButton);
Vue.component('BInputGroup', BInputGroup);
Vue.component("RouterLink", RouterLink);

// 目前 BootstrapVue icon 只用了 3个，支持动画
Vue.component('BIcon', BIcon);
Vue.component('BIconDownload', BIconDownload);
Vue.component('BIconExclamationCircleFill', BIconExclamationCircleFill);
Vue.component('BIconThreeDots', BIconThreeDots);
Vue.component('BIconArrowClockwise', BIconArrowClockwise);

Vue.config.productionTip = false;

// 公共方法
Vue.prototype.Server = Server;
Vue.prototype.Common = Common;
Vue.prototype.Ajax = Ajax;
Vue.prototype.LocalStorage = LocalStorage;
Vue.prototype.moment = moment;


new Vue({
    el: '#app',
    router,
    render: h => h(App)
});